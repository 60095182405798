import { getCookie } from 'lib/cookie';
import { showModal } from 'lib/modal';
import { $, $1, $e, csrfToken, EventOf, on, registerStartup } from 'lib/utils';

interface CurrentCartsItems {
  readonly items: number;
}

registerStartup(() => {
  getCart();
  on('change', 'form[data-cart-recalc] select[name^=quantity]', (ev: EventOf<HTMLSelectElement>) =>
    recalcCart(ev.currentTarget.form!),
  );
  on('click', 'form[data-cart-recalc] button[data-remove-item]', handleRemoveItem);
  on('submit', 'form.cart-add-item', handleCartAddItem);

  on('change', '[data-change-refresh]', (ev: EventOf<HTMLSelectElement>) => {
    location.href = ev.currentTarget.value;
  });
});

async function getCart(force = false) {
  let items = parseInt(getCookie('__Host-cart') || '', 10);
  if (force || Number.isNaN(items) || items < 0) {
    const res = await fetch('/ajax/current_carts_items');
    const json = (await res.json()) as CurrentCartsItems;
    items = json.items;
  }

  const elem = $('cart-quantities') as HTMLOutputElement;
  if (items > 0) Object.assign(elem, { hidden: false, value: items });
}

async function recalcCart(form: HTMLFormElement) {
  try {
    const res = await fetch(form.dataset.cartRecalc!, {
      method: 'PATCH',
      mode: 'same-origin',
      credentials: 'include',
      body: new FormData(form),
      headers: { 'X-CSRF-Token': csrfToken() },
      redirect: 'manual',
    });
    if (res.type === 'opaqueredirect' || res.status === 204) {
      location.reload();
    } else if (!res.ok) {
      alert(
        res.headers.get('Content-Type')?.includes('application/json')
          ? (await res.json()).error
          : '買い物かごの再計算に失敗しました。再読込します。',
      );
      location.reload();
    } else {
      form.querySelector('.cart-form-intern')!.innerHTML = await res.text();
    }
  } catch (err) {
    alert(err);
  }
}

function handleRemoveItem(ev: EventOf<HTMLButtonElement>) {
  const e = ev.currentTarget;
  const form = e.form!;
  if (form.dataset.cartDisallowEmpty && form.querySelectorAll('select[name^=quantity]').length <= 1) {
    alert('買い物かごの商品をすべて削除することはできません。代わりに注文を取り消してください。');
    return;
  }
  const quantity = e.closest('tr')!.querySelector<HTMLSelectElement>('select[name^=quantity]');
  if (quantity && confirm(`『${e.dataset.removeItem}』を買い物かごから削除します。`)) {
    quantity.replaceChildren($e('option', { value: '0' }, '0'));
    quantity.value = '0';
    recalcCart(e.form!);
  }
}

async function handleCartAddItem(ev: EventOf<HTMLFormElement, SubmitEvent>) {
  const form = ev.currentTarget;
  if (!form.checkValidity()) return;

  ev.preventDefault();

  const submitter = ev.submitter as HTMLButtonElement;
  const submitIcon = submitter.querySelector('i')!;
  submitIcon.classList.remove('fa-cart-plus');
  submitIcon.classList.add('fa-spinner');
  submitIcon.classList.add('fa-spin');
  submitter.disabled = true;
  const reset = () => {
    submitIcon.classList.remove('fa-spin');
    submitIcon.classList.remove('fa-spinner');
    submitIcon.classList.add('fa-cart-plus');
    submitter.disabled = false;
    form.classList.remove('is-modified');
    form.querySelectorAll('.is-modified').forEach((el) => el.classList.remove('is-modified'));
  };

  try {
    const res = await fetch(form.action, {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'include',
      body: new FormData(form),
      headers: { 'X-CSRF-Token': csrfToken() },
    });
    if (!res.ok) throw new Error(`HTTP ${res.status}`);
    const json = await res.json();
    if (json.error) throw new Error(json.error);

    getCart(true).catch(console.error);
    if (!form.classList.contains('cart-add-item-inline')) {
      $1('#item-added .result')!.innerHTML = json.result;
      showModal($('item-added'));
      await new Promise((resolve) => setTimeout(resolve, 200));
    } else {
      form.querySelector('.inline-item-added')?.classList.remove('invisible');
      form.reset();
    }
  } catch (err) {
    alert(err);
  } finally {
    reset();
  }
}
